export default {
    getFileInfo(data) {
        const r = JSON.parse(data);
        return `${r.name}   (${(r.size / 1024).toFixed(1)}KB)`
    },
    getImageUrl(data) {
        try {
            const r = JSON.parse(data);
            return r.thumbUrl
        } catch (e) {
            return ""
        }
    },
    getAudioSrc(data) {
        return JSON.parse(data).url;
    },
}